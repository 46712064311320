// import logo from './logo.svg';
import './App.css';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/HomePage.js';
import Blog from './Components/Blog1.js';

function App() {
  return (
    <Router>
      <Box className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/founder-mode" element={<Blog />} />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
