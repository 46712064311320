import React, { useState } from "react";
import bannerImage from "../Assets/banner2.jpg";
import { Box, Button, Typography, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu"; // Import hamburger menu icon
import CloseIcon from "@mui/icons-material/Close"; // Import close icon

const Home = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `url(${bannerImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: "#fff",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          paddingTop: "20px",
          top: 0,
          left: 0,
        }}
      >
        {/* Title with subtitle */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
          <Typography
            variant="h3"
            sx={{
              position: "relative",
              color: "#000",
              fontFamily: "PT Serif, serif",
              fontSize: { xs: "24px", sm: "32px", md: "40px" }, // Responsive font sizes
            }}
          >
            Founder Mode
          </Typography>
          <Typography variant="body1" sx={{ fontSize: { xs: "12px", sm: "14px" }, color: "#333", opacity: "0.7" }}>
            founder mode is lonely but it's <span style={{ fontFamily: 'fantasy' }}>crazy and worth it!</span>
          </Typography>
        </Box>

        {/* Right-side buttons for desktop, hidden on mobile */}
        <Box
          sx={{
            position: "absolute",
            right: "40px",
            display: { xs: "none", md: "flex" }, // Hide on mobile
            gap: 3,
          }}
        >
          <Link to="/founder-mode">
            <Button
              sx={{
                color: "#000",
                textTransform: "none",
                fontSize: "18px",
                fontFamily: "PT Serif, serif",
              }}
            >
              Blog
            </Button>
          </Link>
        </Box>

        {/* Hamburger menu for mobile */}
        <Box
          sx={{
            position: "absolute",
            right: "20px",
            display: { xs: "block", md: "none" }, // Show on mobile
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon sx={{ fontSize: "30px", color: "#000" }} /> {/* Set the color to black */}
          </IconButton>
        </Box>

        {/* Drawer for the mobile menu */}
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerToggle}
          sx={{
            "& .MuiDrawer-paper": {
              width: "250px",
              padding: "10px",
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          {/* Drawer header with close icon */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleDrawerToggle} sx={{ padding: "8px" }}>
              <CloseIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          </Box>

          {/* Drawer menu items */}
          <List>
            <ListItem button component={Link} to="/founder-mode" onClick={handleDrawerToggle}>
              <ListItemText primary="Blog" />
            </ListItem>
            {/* Add other links here if needed */}
          </List>
        </Drawer>
      </Box>

      {/* Placeholder for other content like notification */}
      <Box>{/* <IphoneNotification /> */}</Box>
    </Box>
  );
};

export default Home;
