import { Box, Typography } from "@mui/material";
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Blog = () => {
  return (
    <>
      {/* <header className="App-header">
        <img style={{ height: '30px' }} src='/logo.svg' alt='logo' />
      </header> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box
            sx={{
              flex: 1,
              height: "1px",
              backgroundColor: "#000",
              opacity: 0.5,
              animation: "lineGrowAlt 2s ease-out forwards",
              transformOrigin: "center left",
            }}
          />
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "Libre Baskerville, serif",
              px: 1,
              whiteSpace: "nowrap",
              fontSize: { xs: "25px", sm: "20px", md: "30px", lg: "40px" },
              position: "relative",
            }}
          >
            <span
              style={{
                animation: "glowRotate 2s ease-in-out infinite",
                color: "#000 ",
                opacity: "0.8",
              }}
            >{`{`}</span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 0.2s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              F
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 0.4s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              o
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 0.6s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              u
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 0.8s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              n
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 1s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              d
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 1.2s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              e
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 1.4s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              r
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 1.6s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              {" "}
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 1.8s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              M
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 2s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              o
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 2.2s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              d
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 2.4s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >
              e
            </span>
            <span
              style={{
                animation: "glowRotate 2s ease-in-out 2.6s infinite",
                color: "#000",
                opacity: "0.8",
              }}
            >{`}`}</span>
          </Typography>
          <Box
            sx={{
              flex: 1,
              height: "1px",
              backgroundColor: "#000",
              opacity: 0.5,
              animation: "lineGrowAlt 2s ease-out forwards",
              transformOrigin: "center right",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "8px",
          }}
        >
          <Typography
            sx={{
              margin: "0",
              opacity: "0.5",
              fontFamily: "Libre Baskerville, serif",
              fontSize: "12px",
              fontStyle: "italic",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AccessTimeIcon style={{ height: "15px" }} /> 5:26pm, Sunday, 22
            September 2024
          </Typography>
        </Box>

        <style>
          {`
      @keyframes glowRotate {
        0%, 100% {
          text-shadow: 0 0 5px #000, 0 0 10px #000;
          transform: rotate(0deg);
        }
        50% {
          text-shadow: 0 0 15px #000, 0 0 20px #000;
          transform: rotate(5deg);
        }
      }
    `}
        </style>
        <style>
          {`
      @keyframes lineGrowAlt {
        0% {
          transform: scaleX(0);
        }
        60% {
          transform: scaleX(1.02); 
        }
        100% {
          transform: scaleX(1); 
        }
      }
    `}
        </style>
      </Box>

      <Box
        sx={{
          margin: { xs: "0 20px", sm: "0 40px", md: "0 100px", lg: "0 400px" },
          textAlign: "left",
        }}
      >
        <br />
        <Typography
          sx={{
            fontWeight: "bold",
            margin: "40px 0",
            fontSize: "25px",
            fontStyle: "italic",
            fontFamily: "Libre Baskerville, serif",
          }}
        >
          Back to building in web2
        </Typography>
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          Friends, my name is Aman. Today I am announcing that after working 6
          years in web3 (from 2018 to 2024), I am going back to building in
          web2. While I am 200% convinced about the potential of blockchain
          technology & cryptos, I am not able to find a 'must-have' conviction
          from the users' point of view. Blockchains are special computers that
          anyone can access but no one owns, but I believe these special
          computers need a bit more hand-holding from the users' point of view &
          this would not just be solved by education & rewards (I can be 100%
          wrong here).
        </Typography>
        <br />
        <br />
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          Web3 offers a new way that combines the best aspects of the previous
          eras. But it's still very early in this movement and probably needs
          some more time to evolve.
        </Typography>
        <br />
        <br />
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          While there is so much liquidity & dry powder available in web3
          markets, I am not able to find convincing biz models without a token
          play at large. I am not against the token, but probably the markets I
          am looking to build for are not ready for this exercise. When I say
          biz model, I am talking clearly about customer value proposition with
          a profit formula and key resources/processes.
        </Typography>
        <h2 style={{ fontFamily: "Libre Baskerville, serif" }}>What's Next?</h2>
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          I believe we are in the midst of one of the largest platform shifts in
          history. Just as the personal computer slashed the cost of compute and
          the internet cut the cost of distribution, genAI will rapidly reduce
          the cost of productivity. And from my past experiences, it is very
          clear people pay for efficiency.
        </Typography>
        <br /> <br />
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          I genuinely believe every job function will have an AI copilot. Some
          of these roles will be fully automated with AI agents and this shall
          be built by new age founders/builders because incumbents are often
          slow to respond to changes in technology. In the last few months,
          after shutting down (moving - crypto social investing platform) &
          other side hustles (below), I spent some time going after an industry
          wherein in the past I used to navigate just surface level problems,
          and the only best way to understand about the industry is to read
          extensively and talk to the incumbents in that industry (some 100+
          conversations already).
        </Typography>
        <br /> <br />
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          Based on the conversations, I found - there are significant
          opportunities to improve processes and operations in the particular
          industry. There is an opportunity to build intelligent tools to help
          stakeholders build deeper connections with their customers by offering
          highly personalized experiences. It was very clear to "AI-ify" a
          painful workflow, because by solving a painful workflow, you can
          become the repository of data and earn the right to automate further
          workflows (hidden insight).
        </Typography>
        <br /> <br />
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          AI promises to be something different—a productivity revolution, more
          akin to the personal computer, which shaped the future of business and
          industry and has a clear profit formula from the customers' point of
          view. From the conversations, I found that AI is positioned to
          massively change the cost structure and increase productivity in the
          industry we are going after. Again, I am not against the web3/crypto
          revolution or the technology, but I believe AI might be the driver to
          bring web3 adoption at large. Hence, we are looking to build a
          movement, which is hard but must be done.
        </Typography>
        <br /> <br />
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          Web3 represents a leap towards an internet where users control their
          data, identities, and transactions through blockchain technology.
          Despite its revolutionary potential, the inherent complexity of
          understanding and interacting with Web3 platforms has limited its
          appeal to a broader audience.
        </Typography>
        <br /> <br />
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          My contrarian belief: AI can offer a more usable interface where many
          users struggle with the complexities of web3. Thus, for better user
          experience and reducing the barrier to entry, we are taking the
          driver's seat with AI.
        </Typography>
        <br /> <br />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Libre Baskerville, serif",
            flexDirection: "column",
          }}
        >
          <img
            style={{
              borderRadius: "10px",
              width: "100%",
              boxShadow: "0px 2px 6px 0px #ccc",
            }}
            src="/web3.png"
            alt="img"
          ></img>
          <Typography
            sx={{
              fontFamily: "Libre Baskerville, serif",
              fontSize: "13px",
              marginTop: "10px",
              opacity: 0.5,
            }}
          >
            Ai will an anchor drive people from web2 to web2.5
          </Typography>
        </Box>
        <br /> <br />
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          Building a movement is hard, but it must be done, and it must be done
          by founders. A founder's passion, vision, and conviction can't be
          simulated by others — least of all the press-release-enjoying middle
          managers already scouting for their next jobs. Some interesting web3
          experiments which couldn't work:
        </Typography>
        <ul>
          <li
            style={{
              textAlign: "start",
              fontFamily: "Libre Baskerville, serif",
              fontSize: "15px",
            }}
          >
            In 2018, we built a DocuSign on blockchain. EthSign raised $12M in
            2022—we were early, and it seems they are too (as a Web3 tool).
          </li>
          <br />
          <li
            style={{
              textAlign: "start",
              fontFamily: "Libre Baskerville, serif",
              fontSize: "15px",
            }}
          >
            In 2022, we launched a crypto social investing platform. Centralized
            social investing didn't work; we were wrong. Even our competitors,
            who copied us, pivoted.
          </li>
          <br />
          <li
            style={{
              textAlign: "start",
              fontFamily: "Libre Baskerville, serif",
              fontSize: "15px",
            }}
          >
            In 2023/24, we built digital asset research for advisors. Users
            didn't want to pay for non-price-centric research, and token prices
            are cyclic. Protocols also don't pay upfront for writing. I'll share
            more learnings in the next blog.
          </li>
        </ul>
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          P.S.: I will continue to teach web3, it's my passion. Here is the
          recent video recorded with{" "}
          <a
            style={{
              color: "#0668E1",
              textDecoration: "none",
              fontWeight: "bolder",
              cursor: "pointer",
              fontStyle: "italic",
            }}
            href="https://www.youtube.com/watch?v=j6_WLFKxumY"
          >
            Reliance
          </a>{" "}
          & you can find some more talks on YouTube also. You may also read more
          on my not-so-updated website:{" "}
          <a
            style={{
              textDecoration: "none",
              color: "#0668E1",
              cursor: "pointer",
              fontStyle: "italic",
              fontWeight: "bolder",
            }}
            href="https://asanduja.com"
          >
            asanduja.com
          </a>
        </Typography>
        <Typography
          sx={{ fontFamily: "Libre Baskerville, serif", fontSize: "15px" }}
        >
          In the next blog - what & why we are building in the X industry.
        </Typography>
        <br /> <br />
        <a
          href="https://x.com/amansanduja"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "inherit",
            textDecoration: "none",
            fontFamily: "Libre Baskerville, serif",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              opacity: "0.5",
              fontSize: "12px",
            }}
          >
            Aman Sanduja
          </Box>
        </a>
      </Box>
    </>
  );
};
export default Blog;
